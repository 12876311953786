import TranslationMapper from "i18n/mapper";
import IActivityAction from "interfaces/IActivityAction";
import IActivityExport from "interfaces/IActivityExport";
import ICsvHeader from "interfaces/ICsvHeader";
import moment from "moment";
import LanguageProvider from "providers/languageProvider";
import React from "react";
import { CSVLink } from "react-csv";
import { NotificationManager } from "react-notifications";
import CleaningManagementService from "services/cleaningManagementService";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ActivityExportModal from "./activityExportModal";
import ExportFormatter from "./exportFormatter";
import { ExportType } from "./interfaces/ExportType";
import IActivityExportProps from "./interfaces/IActivityExportProps";
import IActivityExportState from "./interfaces/IActivityExportState";

export class ActivitiesExport extends React.Component<IActivityExportProps, IActivityExportState> {
  private readonly cleaningManagementService: CleaningManagementService;
  public CSVDownloadRef: React.RefObject<{ link: { click: () => void } }>;

  public constructor(props: IActivityExportProps) {
    super(props);

    this.cleaningManagementService = new CleaningManagementService();

    const state: IActivityExportState = {
      exportDisabled: this.props.disabled,
      csvData: [],
      exportType: ExportType.ActivityLogging,
      isModalOpen: false,
    };

    this.CSVDownloadRef = React.createRef();

    this.state = state;
    this.openModal = this.openModal.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalSubmit = this.handleModalSubmit.bind(this);
    this.formatAndSetCsvData = this.formatAndSetCsvData.bind(this);
    this.download = this.download.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
  }

  public componentDidUpdate(prevProps: Readonly<IActivityExportProps>): void {
    if (prevProps.disabled !== this.props.disabled) {
      this.setState({
        exportDisabled: this.props.disabled,
      });
    }
  }

  private get fileName(): string {
    const startDateExport = moment(this.props.startDate, "ddd MMM DD YYYY HH:mm:ss ZZ").format("DD.MM.YY");
    const endDateExport = moment(this.props.endDate, "ddd MMM DD YYYY HH:mm:ss ZZ").format("DD.MM.YY");

    switch (this.state.exportType) {
      case ExportType.ActivityLogging: {
        return `HiFive.ActivityLogging.${startDateExport}-${endDateExport}.csv`;
      }
      case ExportType.ActivityFeedbackLogging: {
        return `HiFive.FeedbackLogging.${startDateExport}-${endDateExport}.csv`;
      }
      case ExportType.ActivityPlanning: {
        return `HiFive.ActivityPlanning.${startDateExport}-${endDateExport}.csv`;
      }
    }

    return `HiFive.ActivityLogging.${startDateExport}-${endDateExport}.csv`;
  }

  private get headers(): ICsvHeader[] {
    switch (this.state.exportType) {
      case ExportType.ActivityLogging: {
        return ExportFormatter.getActivityLoggingHeaders();
      }
      case ExportType.ActivityFeedbackLogging: {
        return ExportFormatter.getActivityFeedbackLoggingHeaders();
      }
      case ExportType.ActivityPlanning: {
        return ExportFormatter.getActivityPlanningHeaders();
      }
    }

    return ExportFormatter.getActivityLoggingHeaders();
  }

  private openModal(): void {
    this.setState({
      isModalOpen: true,
    });
  }

  private handleModalClose(): void {
    this.setState({
      isModalOpen: false,
    });
  }

  private handleModalSubmit(exportType: ExportType): void {
    this.setState({
      exportType: exportType,
      isModalOpen: false,
    });

    this.props.selectedActivities.length === 0
      ? this.getAllActivitiesForExport(exportType)
      : this.formatAndSetCsvData(this.props.selectedActivities, exportType);
  }

  private async getAllActivitiesForExport(exportType: ExportType): Promise<void> {
    if (!this.props.activeCustomerId) {
      return;
    }

    NotificationManager.info(LanguageProvider.t(TranslationMapper.pages.logging.downloadstartedmessage));
    this.setState({
      exportDisabled: true,
    });

    const filter = { ...this.props.filter };
    filter.takeLimited = false;
    filter.isExport = true;

    try {
      const activitiesForExport = await this.cleaningManagementService.getCustomerActivityActions(
        this.props.activeCustomerId,
        filter
      );
      if (activitiesForExport.activityActionResponse.length <= 0) {
        NotificationManager.error(LanguageProvider.t(TranslationMapper.pages.logging.downloaderrormessage));
      }
      this.formatAndSetCsvData(activitiesForExport.activityActionResponse, exportType);
    } catch (error) {
      NotificationManager.error(LanguageProvider.t(TranslationMapper.pages.logging.downloaderrormessage));
    } finally {
      this.setState({
        exportDisabled: false,
      });
    }
  }

  private formatAndSetCsvData(data: IActivityAction[], exportType: ExportType): void {
    let formattedData = [] as IActivityExport[];
    switch (exportType) {
      case ExportType.ActivityLogging: {
        formattedData = ExportFormatter.formatActivityLogging(data);
        break;
      }
      case ExportType.ActivityFeedbackLogging: {
        formattedData = ExportFormatter.formatActivityFeedbackLogging(data);
        break;
      }
      case ExportType.ActivityPlanning: {
        formattedData = ExportFormatter.formatActivityPlanning(data);
        break;
      }
    }

    if (formattedData.length === 0) {
      NotificationManager.info(LanguageProvider.t(TranslationMapper.pages.logging.norecordsfound));
      return;
    }

    this.setState(
      {
        csvData: formattedData,
        exportDisabled: false,
      },
      () => this.download()
    );
  }

  private download(): void {
    const CSVDownloadLink = this.CSVDownloadRef.current;
    if (CSVDownloadLink && this.state.csvData.length > 0) {
      // Initiates download on in browser after data is loaded
      CSVDownloadLink.link.click();
    }
  }

  private onExportClick(): void {
    if (this.state.exportDisabled) {
      return;
    }

    if (!this.props.useModal) {
      this.handleModalSubmit(this.props.defaultExportType);
      return;
    }

    this.openModal();
  }

  private get exportButton(): JSX.Element {
    return (
      <button
        className="btn btn-primary btn--rounded"
        disabled={this.state.exportDisabled}
        onClick={this.onExportClick}
      >
        <FontAwesomeIcon icon={["fal", "arrow-down-to-line"]} fixedWidth />
      </button>
    );
  }

  public render(): JSX.Element {
    return (
      <>
        {this.exportButton}

        {this.props.useModal && this.state.isModalOpen && (
          <ActivityExportModal onClose={this.handleModalClose} onSubmit={this.handleModalSubmit} />
        )}

        <CSVLink
          ref={this.CSVDownloadRef}
          headers={this.headers}
          data={this.state.csvData}
          filename={this.fileName}
          className=""
          target="_blank"
          separator={";"}
        />
      </>
    );
  }
}
