import ICsvHeader from "interfaces/ICsvHeader";
import moment from "moment";
import React from "react";
import { CSVLink } from "react-csv";
import CleaningManagementService from "services/cleaningManagementService";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ExportFormatter from "../components/exportFormatter";
import INotificationsExportProps from "./interfaces/INotificationsExportProps";
import INotificationsExportState from "./interfaces/INotificationsExportState";

export class NotificationsExport extends React.Component<INotificationsExportProps, INotificationsExportState> {
  private readonly cleaningManagementService: CleaningManagementService;
  public CSVDownloadRef: React.RefObject<{ link: { click: () => void } }>;

  public constructor(props: INotificationsExportProps) {
    super(props);

    this.cleaningManagementService = new CleaningManagementService();

    const state: INotificationsExportState = {
      exportDisabled: props.notifications.length < 1,
      csvData: [],
    };

    this.CSVDownloadRef = React.createRef();

    this.state = state;
    this.download = this.download.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
  }

  public componentDidUpdate(prevProps: Readonly<INotificationsExportProps>): void {
    if (this.props.disabled !== prevProps.disabled) {
      this.setState({
        exportDisabled: this.props.disabled,
      });
    }
  }

  private get fileName(): string {
    const startDateExport = moment(this.props.startDate, "ddd MMM DD YYYY HH:mm:ss ZZ").format("DD.MM.YY");
    const endDateExport = moment(this.props.endDate, "ddd MMM DD YYYY HH:mm:ss ZZ").format("DD.MM.YY");

    return `HiFive.Notifications.${startDateExport}-${endDateExport}.csv`;
  }

  private get headers(): ICsvHeader[] {
    return ExportFormatter.getNotificationLoggingHeaders();
  }

  private download(): void {
    const CSVDownloadLink = this.CSVDownloadRef.current;
    if (CSVDownloadLink && this.state.csvData.length > 0) {
      // Initiates download on in browser after data is loaded
      CSVDownloadLink.link.click();
    }
  }

  private onExportClick(): void {
    if (this.state.exportDisabled) {
      return;
    }

    this.setState({
      exportDisabled: true,
    });

    const ids = this.props.notifications.map((x) => x.id);

    this.cleaningManagementService.getCustomerNotifications(ids).then((result) => {
      this.setState(
        {
          csvData: ExportFormatter.formatNotificationLogging(this.props.notifications, result),
          exportDisabled: false,
        },
        () => this.download()
      );
    });
  }

  private get isExportButtonDisabled(): boolean {
    return this.props.notifications.length === 0 || this.state.exportDisabled;
  }

  private get exportButton(): JSX.Element {
    return (
      <button
        className="btn btn-primary btn--rounded"
        disabled={this.isExportButtonDisabled}
        onClick={this.onExportClick}
      >
        <FontAwesomeIcon icon={["fal", "arrow-down-to-line"]} fixedWidth />
      </button>
    );
  }

  public render(): JSX.Element {
    return (
      <>
        {this.exportButton}

        <CSVLink
          ref={this.CSVDownloadRef}
          headers={this.headers}
          data={this.state.csvData}
          filename={this.fileName}
          className=""
          target="_blank"
          separator={";"}
        />
      </>
    );
  }
}
